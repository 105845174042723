<!--
 * @Author: 刘锦
 * @Date: 2020-09-18 11:55:30
 * @LastEditTime: 2022-03-17 14:41:24
 * @LastEditors: Seven
 * @Description: 
 * @FilePath: \workbench-front\src\components\order\order-modal.vue
-->
<template>
 <el-drawer  title="选择订单" :visible.sync="showVisible" size="90%" :before-close="onCancel">          
      <div class="drawer-body">
        <div class="goods-form-wrapper">
          <!-- 搜索表单区 -->
          <el-form
            class="formContent"
            :model="orderModalFields"
            :rules="orderModalRules"
            label-position="right"
            label-width="120px"
            inline
          >
          <el-row :gutter="24" style="padding-top: 10px">
            <el-col :span="6" v-for="item of orderModalItems" :key="item.label">
              <el-form-item
                class="searchItem"
                :style="item.itemStyle"
                :prop="item.prop"
                v-show="moreUpgradeHidden(item)"
              >
                <v-autocomplete
                  v-if="item.type === 'select'"
                  v-model="orderModalFields[item.prop]"
                  :items="item.options"
                  :label="item.label"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="label"
                  item-value="value"
                  :disabled="item.disabled"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                  clear-icon="$clear"
                  hide-details="auto"
                  no-data-text="暂无数据"
                ></v-autocomplete>
                <v-text-field
                  v-else
                  hide-details="auto"
                  v-model="orderModalFields[item.prop]"
                  :label="item.label"
                  :disabled="item.disabled"
                  outlined
                  :dense="true"
                  :height="32"
                ></v-text-field>
            </el-form-item>
            </el-col>
          </el-row>
            <div>
              <el-button type="primary" @click="onSearch">查询</el-button>
              <el-button @click="onReset">重置</el-button>
            </div>
          </el-form>
        </div>
        <div class="goods-table-wrapper">
          <!-- 商品表格区 -->
          <el-table
            style="width: 100%;height:540px"
            border
            stripe
            size="mini"
            element-loading-text="拼命加载中"
            :row-style="{ height: '20px' }"
            :cell-style="{ padding: '0px' }"
            :header-cell-style="{ background: '#e5f2ff' }"
            sortable
            highlight-current-row
            header-cell-class-name="normal-table-header"
            :data="tableData"
            :row-key="(row) => row.orderId"
            ref="multipleTable"
            @selection-change="onSelectChange"
            @current-change="selOrder"
          >
            <el-table-column :show-overflow-tooltip="true"
              type="selection"
              key="selection"
              min-width="55"
              :reserve-selection="true"
              :selectable="selectable"
            ></el-table-column>
            <template v-for="item of columns">
              <el-table-column :show-overflow-tooltip="true" v-if="item.prop === 'createTime'" :label="item.label" :key="item.prop" width="180px">
                <template v-slot:default="{ row }">
                  <p>{{ row.createTime | formatDate('YYYY-MM-DD HH:mm:ss')}}</p>
                </template>
              </el-table-column>
              <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'orderStatus'" :key="item.prop" :label="item.label" width="160px">
                <template v-slot:default="{ row }">
                  <p>{{ orderStatusMap[row.orderStatus] }}</p>
                </template>
              </el-table-column>
              <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'afterSaleTypes'" :key="item.prop" :label="item.label">
                <template v-slot:default="{ row }">
                  <p>{{ row.afterSaleTypes || '无' }}</p>
                </template>
              </el-table-column>
              <el-table-column :show-overflow-tooltip="true"
                v-else
                
                :key="item.label"
                :label="item.label"
                :prop="item.prop"
                :fixed="item.fixed"
                :width="item.width || '160px'"
              />
            </template>
          </el-table>
          <Pagination
            :page.sync="page"
            :pageSize.sync="pageSize"
            :totalPage="totalPage"
            :totalCount="totalCount"
            @pageChange="pageChange"
            @pageSizeChange="pageSizeChange"
          />
        </div>
      </div>
      <div class="drawer-footer">
        <el-button @click="onCancel">返回</el-button>
        <el-button type="primary" @click="onOk">确定</el-button>
      </div>
 </el-drawer>
</template>

<script>
import Checker from '@/utils/Checker'
import { aftersaleTypeEnum, businessIdEnum, orderStatusMap } from "../constants"
import Pagination from './pagination.vue'
export default {
  name: "OrderModal",
  components: {
    Pagination
  },
  props: {
    visible: Boolean,
    aftersaleType: [Number, String],
    orderModalFields: Object,
    orderModalRules: Object,
    orderModalItems: Array,
    // selectMode 0 单选 1 多选
    selectMode: [String, Number],
    businessId: [String, Number],
    mainOrder: Object,//主订单
  },
  data() {
    return {
      showVisible:this.visible,
      currentRadioIndex: "",
      tableData: [],
      columns: [
        { label: "订单号", prop: "orderNo", width: "180px", fixed: true },
        // { label: "商品ID", prop: "goodsId", width: "60px", fixed: true },
        { label: "报读商品", prop: "goodsName", fixed: true },
        { label: "班型", prop: "commodityFullName" },
        {label: "产品线", prop: "productName" },
        {label: "商品分类", prop: "goodsCategoryName" },
        {label: "产品系列", prop: "goodsSeriesName" },
        { label: "学习账号", prop: "mobile" },
        { label: "学员姓名", prop: "stuName" },
        { label: "身份证号", prop: "idCard", width: "180px" },
        { label: "客户编号", prop: "customerCode" },
        // { label: "应收金额", prop: "totalMoney" },
        { label: "已收金额", prop: "amountReceived" },
        { label: "售后", prop: "afterSaleTypes" },
        // { label: "订单状态", prop: "orderStatus" },
        { label: "招生老师", prop: "teacherRecruitName" },
        { label: "上课校区", prop: "signSchoolName" },
        { label: "报名时间", prop: "createTime" },
      ],
      page: 1,
      pageSize: 10,
      totalPage: 1,
      totalCount: 1,
      goodsCateModalVisible: false,
      currentRow: {},
      selectRows: [],
      orderStatusMap,
    }
  },
  methods: {
    onCancel() {
      this.$emit('update:visible', false)
      this.$emit("on-cancel")
    },
    onOk() {
        const rows = JSON.parse(JSON.stringify(this.selectRows))
        this.$emit("on-ok", rows)
    },
    //删除
    deleteRow(orderId) {
      for (let i in this.selectRows) {
        let row = this.selectRows[i]
        if (row.orderId == orderId) {
          // 切换显示
          this.$refs.multipleTable.toggleRowSelection(row, false)
          break
        }
      }
    },
    // 重置数据
    setDeafultData() {
      this.clearSelection()
      this.selectRows = []
    },
    // 查询
    onSearch() {
      this.getTableData()
    },
    // 重置
    onReset() {
      const selectMode = this.selectMode
      // 多升或多转一重置只显示学员相关订单
      Object.keys(this.orderModalFields).forEach((key) => {
        if (selectMode == 1) {
          if(!(key == 'idCard' || key == 'oneOrMore' || key == 'mobile')){
            this.orderModalFields[key] = ""
          }
        } else {
          this.orderModalFields[key] = ""
        }
      })
      this.getTableData()
    },
    onRadioChange(index, row) {
      this.currentRadioIndex = index
      this.currentRow = row
    },
    selOrder(row) {
      console.log("selOrder", row)
      this.currentRow = row
    },
    onSelectChange(rows) {
      console.log("onSelectChange", rows)
      this.selectRows = rows
    },
    pageChange() {
      this.currentRadioIndex = ""
      this.getTableData()
    },
    pageSizeChange() {
      this.currentRadioIndex = ""
      this.page = 1
      this.getTableData()
    },
    // 商品分类弹窗取消回调
    onGoodsCateCancel() {
      this.goodsCateModalVisible = false
    },
    // 商品分类弹窗确定回调
    onGoodsCateOk(cateRow) {
      this.goodsForm.goodsCategoryName = cateRow.name
      this.goodsCateModalVisible = false
    },
    // 获取订单列表表格数据
    async getTableData() {
      const params = {
        ...this.orderModalFields,
        curPage: this.page,
        pageSize: this.pageSize,
        aftersaleType: this.aftersaleType,
        channel: 1
      }
      const { data } = await this.$fetch("thrid_choiceOrderListGZT", params)
      this.tableData = data.records.map((item) => ({ ...item, checked: this.mainOrder.orderId ? this.mainOrder.orderId == item.orderId : false }))
      this.setMainOrderSelected()
      this.totalPage = data.pages
      this.totalCount = data.total
    },
    // 表格换页多选包含之前页面选中的数据
    getRowKeys(row) {
      return row.id //raceid为列表数据的唯一标识
    },

    //清楚所有选中
    clearSelection(rows) {
      console.log("取消所有", rows)
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable && this.$refs.multipleTable.clearSelection()
        this.mainSelected = false
        this.setMainOrderSelected()
      }
    },
    setMainOrderSelected(){
      const mainOrder = this.tableData.find(item => item.checked)
      if(mainOrder && this.$refs.multipleTable && !this.mainSelected){
        this.$refs.multipleTable.toggleRowSelection(mainOrder, true)
        this.mainSelected = true
      }
    },
    // 转班多升一产品线下拉数据
    async getProjectList(){
      if(this.aftersaleType != aftersaleTypeEnum.upgrade) return//只处理升班
      if(this.projectList) return
      const businessId = !Checker.isUndefined(this.businessId) && !Checker.isNull(this.businessId) ? this.businessId : businessIdEnum.cj
      const res = await this.$fetch('thrid_getAllProduct', {statusSchool: 1, businessId})
      if(res && res.data){
        this.projectList = res.data
        for (const item of this.orderModalItems) {
          if (item.prop === "productId") {
            item.options = this.projectList.map((item) => ({ label: item.fullName, value: item.id }))
          }
        }
      }
    },
    // 设置主订单不可修改选择状态
    selectable(row, index){
      if(this.mainOrder && this.mainOrder.orderId === row.orderId){
        return false
      }
      return true
    }
  },
  computed: {
    //简化computed
    isCurrentRow() {
      return this.currentRow && Object.keys(this.currentRow).length > 0
    },
    isSelectRows() {
      return this.selectRows.length > 0
    },

    moreUpgradeHidden() {
      return function (val) {
        if (this.orderModalFields.oneOrMore === "1") {
          if (
            val.label === "学员编号" ||
            val.label === "学员姓名" ||
            val.label === "学员账号" ||
            val.label === "身份证号" ||
            val.label === "手机账号"
          ) {
            return false
          }
        }
        return true
      }
    },
  },
  watch: {
    visible: {
      handler(visible) {
        this.showVisible = visible;
        this.getProjectList()
        if( visible ){
          this.orderModalFields.oneOrMore = "1"
          this.getTableData();
        }
      },
    },
  },
}
</script>

<style lang="scss">

.drawer-body {
  width: 94%;
  margin: 0 auto;
  .goods-form-wrapper {
    .formContent {
      display: flex;
      flex-wrap: wrap;
    }
    .searchItem {
      // display: flex;
      // width: 300px;
      width: 100%;

      .el-form-item__content {
        width: 100%;
      }
    }
    ::v-deep .el-input__suffix {
      right: 12px;
    }
  }
  .actions-btn {
    padding-left: 68px;
  }
  .goods-table-wrapper {
    // width: 100%;
    margin-top: 10px;
    .el-radio__label {
      display: none;
    }
  }
}

.drawer-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90px;
}
     

.drawer-enter-active,
.drawer-leave-active {
  transition: 0.3s;
}
.drawer-enter {
  transform: translate(100%);
}
.drawer-leave-to {
  transform: translate(100%);
}
::v-deep .el-form-item__label {
  text-align: left !important;
}
</style>
